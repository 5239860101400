import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACK,
  //baseURL: 'http://localhost:3000', // esto es para local
});
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor para manejar errores de respuesta
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Manejar la lógica de renovación del token o redireccionar a la página de inicio de sesión
      console.log('Token expirado o no válido. Redireccionando a la página de inicio de sesión.');
      // Puedes redirigir a la página de inicio de sesión o realizar otras acciones según tus necesidades.
    }
    return Promise.reject(error);
  }
);

axios.defaults.headers.common['Authorization'] ='Bearer ' + localStorage.getItem('token');
export default instance;
