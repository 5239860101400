import Vue from 'vue'
import VueRouter from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import DashboardComponent from '../components/DashboardComponent.vue'
import UsersComponent from '../components/UsersComponent.vue'
import BagComponent from '../components/BagComponent.vue'
import LoginComponent from '../components/LoginComponent.vue'
import ContainerComponent from '../components/ContainerComponent.vue'
import RecoverComponent from '../components/RecoverComponent.vue'
import ResetComponent from '../components/ResetComponent.vue'
import UserComponent from '../components/UserComponent.vue'
import LocationsComponent from '../components/LocationsComponent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    /*name: 'container',*/
    component: ContainerComponent,
    meta: { requiresAuth: true }, // Esta ruta requiere autenticación
    children: [
      {
        path: '',
        name: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'users',
        name: 'users',
        component: UsersComponent 
      },
      {
        path: 'bag/:id',
        name: 'bag',
        component: BagComponent 
      },
      {
        path: 'user/:id',
        name: 'user',
        component: UserComponent
      },
      {
        path: 'locations',
        name: 'locations',
        component: LocationsComponent
      }
    ]
  },
  { 
    path: "/login", 
    name: 'login',
    component: LoginComponent
  },
  { 
    path: "/recover", 
    name: 'recover',
    component: RecoverComponent
  },
  { 
    path: "/reset/:hash", 
    name: 'reset',
    component: ResetComponent
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
