    <template>

  <div>
    <div class="row row-fluid justify-content-between align-items-center action-bar">
      <div class="col-md-2">
        <router-link
            :to="{ path: '/users/'}"
            class="btn-back btnback"><
        </router-link>                    
      </div>
    </div>

    <div class="row row-fluid justify-content-between align-items-center action-bar">
      <div class="col-md-6">  
        &nbsp;
      </div>
      <div class="col-md-6 d-flex align-items-end justify-content-end">
        <div class="mostrardesplegable">
            <button class="btn btn-danger" v-if="this.role_id==6" disabled @click="deleteUser" >Delete</button>            
            <button class="btn btn-danger" v-else @click="deleteUser" >Delete</button>            
        </div>
        <div class="mostrardesplegable">
            <b-button class="btn btn-warning" id="editButton" @click="editUser">Edit</b-button>
        </div>
        <div class="mostrardesplegable">
            <b-button class="btn btn-info" id="editButton" @click="resetPasswdUser()">Reset Password</b-button>
        </div>
      </div>
    </div>

    <div class="container contentwh datatab">      

        <div class="py-3 pl-6 pr-6">            

            <div class="row row-fluid justify-content-between action-bar">
                <p v-text="infodata"></p>

                <div class="col-md-12">                      
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-3 titledata">
                            Name:
                        </div>
                        <div class="col-md-9 contentdata">
                            {{this.user_name}}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">                      
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-3 titledata">
                            Email:
                        </div>
                        <div class="col-md-9 contentdata">
                            {{this.email}}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">                   
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-3 titledata">
                            Rol:
                        </div>
                        <div class="col-md-9 contentdata">
                            {{this.rol}}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">                   
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-3 titledata">
                            InspectionTypeName:
                        </div>
                        <div class="col-md-9 contentdata">
                            {{ this.inspection_type_name }}
                        </div>
                    </div>
                </div>
                <div class="col-md-12">                   
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-12 titledata">
                            Fields:
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-12 contentdata">
                            <table class="table table-borderless table-vertical-center listados">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Customer</th>
                                        <th>Country</th>
                                        <th>City</th>
                                        <th>Location</th>
                                        <th>Users</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="mb-3" v-for="location in campos" :key="location.location_id">
                                        <td>
                                            <span v-if="location.idlo">{{ '✅' }}</span>
                                            <span v-else>{{ '❌' }}</span>
                                        </td>
                                        <td class="pl-0">
                                            <span
                                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                                v-text="location.customer_name"
                                            ></span>
                                        </td>
                                        <td class="pl-0">
                                            <span
                                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                                v-text="location.country_name"
                                            ></span>
                                        </td>
                                        <td class="pl-0">
                                            <span
                                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                                v-text="location.city_name"
                                            ></span>
                                        </td>
                                        <td class="pl-0">
                                            <span
                                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                                v-text="location.location_name"
                                            ></span>
                                        </td>
                                        <td class="pl-0">
                                            <span
                                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                                v-text="location.user_name"
                                            ></span>
                                        </td>
                                        <td class="pl-0">
                                            <b-button class="btn btn-info" id="add" v-if="!location.idul" @click="addLocationUser(location.location_id, location.user_id)">➕</b-button>
                                            <b-button class="btn btn-danger" id="delete" v-if="location.idul" @click="deleteLocationUser(location.idul)">🗑️</b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!--p>{{campos}}</p-->
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <b-modal ref="editUser" title="Edit user" size="lg"
      @show="resetModalEditUser"
      @hidden="resetModalEditUser"
      @ok="handleOkEditUser">      
        <form ref="form" @submit.stop.prevent="handleSubmitEditUser">
            <!-- <div class="alert alert-danger" role="alert" v-if="error !== null">
                {{ error }}
            </div> -->
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Email</label>
                    <input id="email" type="email" class="form-control" v-model="emailEdit" required autofocus autocomplete="off" placeholder="Email">
                </div>
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Name</label>
                    <input id="nam" type="text" class="form-control" v-model="user_nameEdit" required autofocus autocomplete="off" placeholder="Name">
                </div>
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <div class="row-fluid row align-items-center">
                        <div class="col-md-6">
                            <label class="form-group">Role</label>
                            <select id="role" class="form-control" v-model="selectedRole" required placeholder="Role">
                                <option v-for="role in arrayRoles" v-bind:key="role.role_id" v-bind:value="role">
                                    {{ role.role_name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label class="form-group">Inspector Type</label>
                            <select id="role" class="form-control" v-model="selectedInspectorType" required placeholder="Inspector Type">
                                <option v-for="inspector in arrayInspectorsTypes" :key="inspector.inspection_type_id" :value="inspector">
                                    {{ inspector.inspection_type_name }}
                                </option>
                            </select>
                        </div>
                    </div>    
                </div>
            </div>            
        </form>
    </b-modal>

  </div>

</template>

<script>
    import axios from 'axios';
    import Vue from 'vue';
    import 'viewerjs/dist/viewer.css'
    import VueViewer from 'v-viewer'  
    import auth from "../logic/auth";
    import Swal from 'sweetalert2'
    Vue.use(VueViewer)

    export default { 
        data(){
            return{                
                user:[],
                arrayRoles:[],
                email: "",
                user_name: "", 
                password: "", 
                repeatpassword: "", 
                emailEdit: "",
                user_nameEdit: "", 
                passwordEdit: "", 
                repeatpasswordEdit: "", 
                rol: "",  
                user_id: "",
                selectedRole: "",
                role_id: "",
                inspection_type_id: null,
                inspection_type_name: "",
                selectedInspectorType:"",
                arrayInspectorsTypes:[],
                infodata: null,
                campos: []

            }
        },
        methods:{
              show() {
                this.$viewerApi({
                  images: this.arrayImages,
                  options:{ 
                    "inline": true, 
                    "button": true,                 
                    "navbar": false, 
                    "title": false, 
                    "toolbar": false, 
                    "tooltip": true, 
                    "movable": false, 
                    "zoomable": true, 
                    "rotatable": false, 
                    "scalable": true, 
                    "transition": true, 
                    "fullscreen": true, 
                    "keyboard": false}
                })
              },
            getUser(data){                
                let me =this;
                console.log("getUser");
                console.log(me.user_id);
                let url = process.env.VUE_APP_BACK+'/users/'+me.user_id;//+me.page+'/'+me.porpag+'/'+me.searchtext;
                axios.get(url).then(function (response) {
                    console.log("GETUSER");
                    console.log(response.data);
                    me.user = response.data;   
                    me.email = response.data.email;             
                    me.user_name = response.data.user_name;             
                    me.rol = response.data.role_name;             
                    me.user_id = response.data.user_id;
                    me.role_id = response.data.role_id;   
                    me.inspection_type_id = response.data.inspection_type_id;          
                    me.inspection_type_name = response.data.inspection_type_name;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
                this.user = me.user;
                this.email = me.email;
                this.user_name = me.user_name;
                this.rol = me.rol;
                this.user_id = me.user_id;
                this.role_id = me.role_id;
            },
            getLocationsUsers(){
                let me = this;
                console.log("me.getLocationsUsers");
                console.log(me.user_id);
                let url = process.env.VUE_APP_BACK + '/locations/locationsUsers/'+me.user_id;
                axios.get(url).then(function (response) {
                    console.log(response.data);
                    me.campos = response.data;         
                })
                .catch(function (error) {
                    console.log(error);
                });
                this.campos = me.campos;
            },
            getRoles(){
                let me = this;
                let url = process.env.VUE_APP_BACK+'/users/roles/all/';//+me.page+'/'+me.porpag+'/'+me.searchtext;
                console.log(this.email);
                axios.get(url).then(function (response) {
                    console.log(response.data);
                    me.arrayRoles = response.data;         
                })
                .catch(function (error) {
                    console.log(error);
                });
                this.arrayRoles = me.arrayRoles;
            },
            deleteUser(){                                
                Swal.fire({
                  title: 'Are you sure?',
                  icon: 'error',
                  html: "You can't undo this action",
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: 'Yes',
                  denyButtonText: 'No',
                }).then((result) => {
                  if (result.isConfirmed) {
                    let me =this;
                    let url = process.env.VUE_APP_BACK+'/users/'+me.user_id;//+me.page+'/'+me.porpag+'/'+me.searchtext;
                    axios
                        .delete(url, {
                            user: me.user_id
                        })
                        .then((response) => {
                            //console.log(response.data.message);
                            this.$router.push('/users');
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    Swal.fire('Deleted!', '', 'success')
                  }
                });  
            },
            addLocationUser(location_id, user_id){
                console.log(location_id);
                console.log(user_id);
                let me = this;
                let url = process.env.VUE_APP_BACK+'/users/location';
                axios
                    .post(url, {
                        location_id: location_id,
                        user_id: user_id
                    })
                    .then((response) => {
                        //console.log(response.data.message);
                        //this.$router.push('/locations');
                        //this.getLocations();
                        this.getLocationsUsers();
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            },
            deleteLocationUser(location_id){     
            console.log(location_id);     
                Swal.fire({
                  title: 'Are you sure?',
                  icon: 'error',
                  html: "You can't undo this action",
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: 'Yes',
                  denyButtonText: 'No',
                }).then((result) => {
                  if (result.isConfirmed) {
                    let me = this;
                    let url = process.env.VUE_APP_BACK+'/users/location/'+ location_id;
                    axios
                        .delete(url, {
                            userLocation: location_id
                        })
                        .then((response) => {
                            //console.log(response.data.message);
                            //this.$router.push('/locations');
                            //this.getLocations();
                            this.getLocationsUsers();
                        })
                        .catch(function (error) {
                            console.error(error);
                        });
                    Swal.fire('Deleted!', '', 'success')
                  }/* else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                  }*/
                });      
                
            },
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                this.emailState = valid
                this.nameState = valid
                this.passwordState = valid
                this.roleState = valid
                if (valid)
                    return valid
                else {
                    if (this.repeatpasswordEdit != this.passwordEdit){
                        this.error = "Password doesn't match"
                    }
                    else{                        
                        this.error = "Error. Check every field please"
                    }
                }
            },
            editUser(){ 
                this.getInspectorsTypes();
                this.$refs['editUser'].toggle('#editButton');
                this.getRoles();
                setTimeout(()=>{
                    this.selectedRole = this.arrayRoles.find(role => role.role_name === this.user.role_name);
                },100);

                setTimeout(() => {
                    this.selectedInspectorType = this.arrayInspectorsTypes.find(inspector => inspector.inspection_type_id === this.inspection_type_id);
                }, 500); // 2000 milisegundos = 2 segundos

                console.log("user");
                console.log(this.user);
                this.emailEdit = this.user.email;
                this.user_nameEdit = this.user.user_name;
                console.log(this.user.role_name);
                /** TODO Roles */
                //this.selectedRole = this.arrayRoles.find(role => role.RoleName === user.RoleName);
                
            
            },
            getInspectorsTypes(){
                let me = this;  
                let url = process.env.VUE_APP_BACK+'/users/inspectors/all';
                axios.get(url).then(function (response) {
                        me.arrayInspectorsTypes = response.data;
                        console.log(response.data);             
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    });
            },
            async resetPasswdUser(){
                console.log("RESET Password");
                console.log(this.user.email);
                Swal.fire({
                      title: 'Sending email',
                      icon: 'info',
                      html: "Follow steps on the email sended.",
                      showConfirmButton: true,
                    });
                try {        
                    let data = await auth.recover(this.email);
                    const user = {
                      email: this.email
                    };
                    console.log(data);
                    this.infodata = data.data.msg;
                    console.log('EMAIL USER RECOVER PASS: '+user.email);                    
                    //auth.setUserLogged(user.email);
                    //this.$router.push("/login");
                  } catch (error) {
                    console.log(error);
                    this.error = true;
                  }
            },
            resetModalEditUser() {
                this.emailEddit = ''
                this.user_nameEddit = ''
                this.passwordEddit = ''
                this.role = 0
                this.repeatpasswordEdit = ''
                this.error = null
                this.emailState = null
                this.passwordState = null
                this.roleState = null
            },
            handleOkEditUser(bvModalEvent) {
                // Prevent modal from closing
                bvModalEvent.preventDefault();
                // Trigger submit handler
                this.handleSubmitEditUser();
            },
            handleSubmitEditUser() {
                // Exit when the form isn't valid
                console.log("test")
                // if (!this.checkFormValidity()) {
                //   return
                // }
                //if (this.selectedRole) {
                    const role_id = this.selectedRole.role_id;
                    const inspection_type_id = this.selectedInspectorType.inspection_type_id;
                    console.log(role_id);
                    console.log(this.user_nameEdit);
                    console.log("const inspection_type_id");
                    console.log(inspection_type_id);
                    axios
                        .put(process.env.VUE_APP_BACK+"/users/" + this.user_id, {
                            email: this.emailEdit,
                            user_name: this.user_nameEdit,
                            role_id: role_id,
                            inspection_type_id: inspection_type_id
                            //PasswordHash: this.passwordEdit,
                        })
                        .then((response) => {
                            this.resetModalEditUser()
                            this.$refs['editUser'].toggle('#editButton')
                            this.getUser(this.user_id);
                            this.getLocationsUsers();           

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                //}

                this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')    
                })
                
            },
        },
        mounted() {                 
           this.user_id = this.$route.params.id;    
           this.getUser(this.$route.params.id);
           this.getLocationsUsers();
        }
    }
</script>