<template>

  <div>
    <div class="row row-fluid justify-content-between align-items-center action-bar">
      <div class="col-md-2">
        <router-link
            :to="{ path: '/'}"
            class="btn-back btnback"
                    ><
		</router-link>                    
      </div>
    </div>

    <ul class="tab-bag">
		<li @click="showData" class="active">Data</li>
		<li @click="showImages">Images</li>
	</ul>

    <div class="container contentwh datatab">      
        
        <div class="py-3 pl-6 pr-6">        	

        	<div class="row row-fluid justify-content-between action-bar">
      			<div class="col-md-2 qr-size brdata">      				
                    <div><qrcode-vue :value="qr" :size="size" level="H"></qrcode-vue></div>
                    <div class="row row-fluid justify-content-between align-items-center" style="margin-top:30px; color: #E54100; font-size: 1.2em; font-weight: bolder; margin-bottom:10px; border-bottom:1px dotted black">
                        <div class="col-md-12 titledata">
                            GPS:
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-12 contentdata">
                            <a :href="'https://www.google.es/maps/@' + bag.gps + ',7z'" target="_blank">{{bag.gps}}</a>
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center" style="margin-top:30px; color: #E54100; font-size: 1.2em; font-weight: bolder; margin-bottom:10px; border-bottom:1px dotted black">
                        <div class="col-md-12 titledata">
                            GPS Location:
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-12 contentdata">
                            {{bag.gps_location}}
                        </div>
                    </div>
      			</div>
      			<div class="col-md-5 brdata">
      				<div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-12 titledata">
                            <span style="color: #E54100; font-size: 1.2em; font-weight: bolder; margin-bottom:20px; border-bottom:1px dotted black">LOCATION: 🟩</span>
                        </div>                        
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Customer Name:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.customer.customer_name}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            GMT Date&Time:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.date_gmt}} {{bag.time_gmt}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Country:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.country.country_name}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            City:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.city.city_name}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Location:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.location.location_name}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Inspection Type:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.inspectiontype.inspection_type_name}}
                        </div>
                    </div>

                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-12 titledata">
                            <span style="color: #E54100; font-size: 1.2em; font-weight: bolder; margin-bottom:20px; border-bottom:1px dotted black"><br>TRUCK: 🟩</span>
                        </div>                        
                    </div>                    
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            OP ID (esto es ID CHARGER):
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.id_charger}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Receiver (VIENE DEL BAG):
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.receiver}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Truck Plate:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.truck_plate}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Truck ID:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.truck_id}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Container Booking Ref:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.cont_booking}}❓
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            N Units:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.n_units}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Unit 1:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.trailler_1_plate}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Unit 2:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.trailer_2_plate}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) TRUCK GROSS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.gross_kg}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) TRUCK TARE:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.tare_kg}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) TRUCK NET:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.net_kg}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            TRUCK WEIGHT DIFFERENCE:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.net}}❓
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            DIRECT FROM MINE:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.direct_from_mine}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            SUB LOT NR:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.truck.sub_lot_nr}}
                        </div>
                    </div>

                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-12 titledata">
                            <span style="color: #E54100; font-size: 1.2em; font-weight: bolder; margin-bottom:20px; border-bottom:1px dotted black"><br>UNIT:</span>
                        </div>                        
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Actual:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer_actual}}
                        </div>
                    </div>                    
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            UNIT 1 BAGS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.unit_bags}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            UNIT 2 BAGS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.unit_bags}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            NR BAGS Reg:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.num_bags}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (LABEL) UNIT GROSS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.kam_tot_obj_gross}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (LABEL) UNIT NET:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{parseFloat(bag.trailer.kam_tot_obj_net).toFixed(2)}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) UNIT GROSS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.trailer.weighed_tot_obj_gross}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) UNIT NET:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{parseFloat(bag.trailer.weighed_tot_obj_net).toFixed(2)}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            UNIT WEIGHT DIFFERENCE:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{parseFloat(bag.trailer.cont_net_diff).toFixed(2)}}
                        </div>
                    </div>                    
                </div>
                <div class="col-md-5">   
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-12 titledata">
                            <span style="color: #E54100; font-size: 1.2em; font-weight: bolder; margin-bottom:20px; border-bottom:1px dotted black"><br>BAG:</span>
                        </div>                        
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Bag Number:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.bag_number}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Lot Number:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.lot_number}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Client Seal QR:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.kam_seal_nr}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Client Seal Recheck:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.client_seal_re_check}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            3RD PARTY Seal:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.third_party_seal}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            3rd Party PL Match:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.kam_seal_match}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            3rd Party Packing Seal Nr:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.kam_seal_nr}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (LABEL) BAG GROSS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.kam_grossw}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (LABEL) BAG TARE:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.kam_bagw}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (LABEL) BAG NET:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.kam_netw}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) BAG GROSS:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.gross_kg}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            (WEIGHED) BAG NET:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.bag_kg}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            BAG WEIGHT DIFFERENCE:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{parseFloat(bag.difference).toFixed(2)}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Weight Issue:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.weight_issue}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            RADIATION LEVEL:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.radiation}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Color of Stiching Thread:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.colors}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Bag Inspected:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.inspected}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Bag CONDITION:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.condition_bag}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Seal CONDITION:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.seal_bag}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Cargo CONDITION:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.cargo_condition}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Segregation:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.segregation}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Re-Seal Nr:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.reseal_nr}}
                        </div>
                    </div>
                    <div class="row row-fluid justify-content-between align-items-center">
                        <div class="col-md-6 titledata">
                            Observations:
                        </div>
                        <div class="col-md-6 contentdata">
                            {{bag.observation}}
                        </div>
                    </div>
      			</div>
                <div class="col-md-2">
                </div>
      		</div>

        </div>

    </div>

    <div class="container contentwh imagestab">
    	<div class="py-3 pl-6 pr-6">        	

        	<div class="row row-fluid justify-content-evenly align-items-center action-bar images" v-viewer>
                <div class="col-md-3 image-bag" v-for="(image, index) in arrayImages" :key="index" style="overflow: hidden; margin-bottom:30px">
                    <div class="overlay" :src="image" @click="showImage(index)">
                        <b-icon-zoom-in class="fillwhite"></b-icon-zoom-in>
                    </div>
                    <img :src="image" style="width:100%; height:auto">                    
                    <a :href="image" target="_blank" class="download"><b-icon-download class="fillorange"></b-icon-download></a>
                </div>
            </div>

      	</div>
    </div>

  </div>

</template>

<script>
	import axios from 'axios';
    import Vue from 'vue';
    import 'viewerjs/dist/viewer.css';
    import VueViewer from 'v-viewer';  
    import QrcodeVue from 'qrcode.vue';
    Vue.use(VueViewer);

    export default { 
        data(){
            return{                
                bag:null,
                arrayImages:[],
                qr:null,
                page: 1,
                total:0,
                porpag:5,
                searchtext:null,
                bagId:null,
                size: 180,
            }
        },
        components: {
          QrcodeVue,
        },
        methods:{
              showImage(index) {
                this.$viewerApi({
                  images: this.arrayImages,
                  options:{ 
                    "inline": true, 
                    "button": true,                 
                    "navbar": true, 
                    "title": false, 
                    "toolbar": false, 
                    "tooltip": true, 
                    "movable": false, 
                    "zoomable": true, 
                    "rotatable": false, 
                    "scalable": true, 
                    "transition": true, 
                    "fullscreen": true, 
                    "keyboard": true
                    }
                });                
              },
            getBag(data){                
                let me =this;
                let url = process.env.VUE_APP_BACK+'/bags/id/'+me.bagId;
                axios.get(url).then(function (response) {
                    me.qr = response.data.bag_number;
                    me.arrayImages = response.data.images;
                    me.bag = response.data.bag;
                })
                .catch(function (error) {
                    // handle error
                    console.error(error);
                });
            },
            showImages(){
            	jQuery(".imagestab").toggle();
            	jQuery(".datatab").toggle();
            	jQuery(".tab-bag li").toggleClass('active');
            },
            showData(){
            	jQuery(".imagestab").toggle();
            	jQuery(".datatab").toggle();
            	jQuery(".tab-bag li").toggleClass('active');
            }
        },
        mounted() {                	
           this.bagId = this.$route.params.id;    
           this.getBag(this.$route.params.id);
        }
    }
</script>