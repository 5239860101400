import axios from "@/services/axiosConfig";
import Cookies from "js-cookie"; 

const ENDPOINT_PATH = process.env.VUE_APP_BACK;

export default {
  setUserLogged(userLogged) {
    Cookies.set("userLogged", userLogged);
  },
  getUserLogged() {
    //console.log(Cookies.get("userLogged"));
    return Cookies.get("userLogged");
  },
  deleteUserLogged() {
    //console.log("FUERA COOKIE");
    Cookies.remove('userLogged');
  },  
  register(email, password) {
    const user = { email, password };
    return axios.post(ENDPOINT_PATH + "/register", user);
  },
  recover(email) {    
    const user = { email };
    return axios.post(ENDPOINT_PATH + "/users/recover", user);
  },
  
  async login(email, password) {
    try {
      const response = await axios.post("/users/login", { email, password });
      const token = response.data.token;

      // Almacena el token en localStorage
      localStorage.setItem('token', token);

      // Configura el token en las cabeceras de Axios
      axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';

      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }
};