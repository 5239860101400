<template>
  <div class="fullheight">     
    <router-view></router-view>
    <router-view name="login"></router-view>      
    <router-view name="recover"></router-view>      
    <router-view name="reset"></router-view>      
  </div>

 </template>

 <script>        
    export default {     
        
    }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
