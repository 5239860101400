<template>

  <div>
    <div class="row row-fluid justify-content-between align-items-center action-bar">
      <div class="col-md-2">
        <b-icon-search class="inner"></b-icon-search><input class="form control-form busqueda" placeholder="Search user" v-model="searchtext" @change="getUsers">
      </div>
      <div class="col-md-2 d-flex align-items-end justify-content-end">
        <b-button class="btn btn-primary btndash" id="toggle-btn" @click="toggleModal">Create user</b-button>
      </div>
    </div>
    
    <div class="container contentwh">      
        
        <div class="py-3 pl-6 pr-6">
            <div class="table-responsive">
                <table class="table table-borderless table-vertical-center listados">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="mb-3" v-for="user in arrayUsers" :key="user.user_id">
                            <td class="pl-0">
                                <router-link
                                    :to="{ path: '/user/' + user.user_id}"
                                    class="link-dash-gps"
                                >
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="user.email"
                                ></span>
                                </router-link>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="user.user_name"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="user.role_name"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                >{{ formatDate(user.createdAt) }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

    <b-modal ref="createUser" title="Create user" size="lg"
      @show="resetModalcreateUser"
      @hidden="resetModalcreateUser"
      @ok="handleOkcreateUser">      
        <form ref="form" @submit.stop.prevent="handleSubmitcreateUser">
            <div class="alert alert-danger" role="alert" v-if="error !== null">
                {{ error }}
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <div class="row-fluid row align-items-center">
                        <div class="col-md-3">
                            <label class="form-group">Email</label>
                            <input id="email" type="email" class="form-control" v-model="email" required autofocus autocomplete="off" placeholder="Email">
                        </div>
                        <div class="col-md-3">
                            <label class="form-group">Name</label>
                            <input id="nam" type="text" class="form-control" v-model="name" required autofocus autocomplete="off" placeholder="Name">
                        </div>
                        <div class="col-md-3">
                            <label class="form-group">Password</label>
                            <input id="password" type="password" class="form-control" v-model="password" required autocomplete="off" placeholder="Password">
                        </div>
                        <div class="col-md-3">
                            <label class="form-group">Repeat Password</label>
                            <input id="repeatpassword" type="password" class="form-control" v-model="repeatpassword" required autocomplete="off" placeholder="Password">
                        </div>
                    </div>
                    <div class="row-fluid row align-items-center">
                        <div class="col-md-6">
                            <label class="form-group">Role</label>
                            <select id="role" class="form-control" v-model="selectedRole" required placeholder="Role">
                                <option v-for="role in arrayRoles" :key="role.role_id" :value="role">
                                    {{ role.role_name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label class="form-group">Inspector Type</label>
                            <select id="inspectortype" class="form-control" v-model="selectedInspectorType" required placeholder="Inspector Type">
                                <option v-for="inspector in arrayInspectorsTypes" :key="inspector.inspection_type_id" :value="inspector">
                                    {{ inspector.inspection_type_name }}
                                </option>
                            </select>
                        </div>
                    </div>            
                    <div class="row-fluid row align-items-center">
                        <div class="col-md-3">
                            <label class="form-group">Customers</label>
                            <select id="customer" class="form-control" v-model="selectedCustomer" @change="customerSelected" placeholder="Customer">                                
                                <option v-for="customer in customers" :key="customer.customer_id" :value="customer">
                                    {{ customer.customer_name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-group">Countries</label>
                            <select id="country" class="form-control" v-model="selectedCountry" @change="countrySelected" placeholder="Countries">                                
                                <option v-for="country in countries" :key="country.country_id" :value="country">
                                    {{ country.country_name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-group">Cities</label>
                            <select id="city" class="form-control" v-model="selectedCity" @change="citySelected" placeholder="Cities">                                
                                <option v-for="city in cities" :key="city.city_id" :value="city">
                                    {{ city.city_name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label class="form-group">Locations</label>
                            <select id="location" class="form-control" v-model="selectedLocation" @change="locationsSelected" placeholder="Locations">                                
                                <option v-for="location in locations" :key="location.location_id" :value="location">
                                    {{ location.location_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <b-button :disabled="selectedLocation.length === 0" class="btn btn-primary btndash" style="width:100%; margin-bottom: 30px;" @click="saveUserLocations">➕ zona</b-button>
                </div>
            </div>
        </form>
        <div class="row-fluid row align-items-center"> 
            <div class="col-md-12">          
                <p>El usuario que se va a crear va a tener los siguientes campos asocidos:</p>
                <table class="table table-borderless table-vertical-center listados">
                    <tbody>
                        <tr class="mb-3" >
                            <td class="pl-0" style="width: 80px;" >Customers</td>
                            <td class="pl-0" ><span v-for="cus in allSelectedCustomers">{{ getCustomerName(cus) }}, </span></td>
                        </tr>
                        <tr class="mb-3" >
                            <td class="pl-0" style="width: 80px;" >Countries</td>
                            <td class="pl-0" ><span v-for="cus in allSelectedCountries">{{ getCountryName(cus) }}, </span></td>
                        </tr>
                        <tr class="mb-3" >
                            <td class="pl-0" style="width: 80px;" >Cities</td>
                            <td class="pl-0" ><span v-for="cus in allSelectedCities">{{ getCityName(cus) }}, </span></td>
                        </tr>
                        <tr class="mb-3" >
                            <td class="pl-0" style="width: 80px;" >Locations</td>
                            <td class="pl-0" ><span v-for="cus in allSelectedLocations">{{ getLocationName(cus) }}, </span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </b-modal>

  </div>
</template>

<script>    
    var moment = require("moment");
    import axios from 'axios';
    import auth from "../logic/auth";
    import Swal from 'sweetalert2'

    export default { 
        data(){
            return{                
                arrayUsers:[],
                arrayRoles:[],
                arrayInspectorsTypes:[],
                customers:[],
                countries:[],
                locations:[],
                locationsAll:[],
                countriesAll:[],
                citiesAll:[],
                cities:[],
                page: 1,
                total:0,
                porpag:5,
                email: "",
                name: "",
                password: "",
                repeatpassword: "",
                role:0,
                emailState: null,
                nameState: null,
                passwordState: null,
                roleState: null,
                error: null, 
                searchtext:null,
                locations_users:null,
                selectedRole: "",
                selectedInspectorType: "",
                selectedCustomer: [],
                selectedCountry: [],
                selectedCity: [],
                selectedLocation: [],
                allSelectedCustomers: [],
                allSelectedCountries: [],
                allSelectedCities: [],
                allSelectedLocations: [],
                locationSend: "",
            }
        },
        methods:{
            async saveUserLocations() {
              let locationsToSave = [];              
              if (this.selectedLocation.location_id == "0") {
                if(this.selectedCity.city_id=="0"){
                   if (this.selectedCountry.country_id == "0")
                    {
                        if (this.selectedCustomer.customer_id == "0")
                        {
                            const countries = await this.fetchAllCountries();
                            for (const country of countries) {
                              const countryCities = await this.fetchCities(country.country_id);
                              for (const city of countryCities) {
                                const cityLocations = await this.fetchLocations(city.city_id);
                                locationsToSave.push(...cityLocations);
                              }
                            }
                        }
                        else{
                            const countries = await this.fetchCountries(this.selectedCustomer.customer_id);
                            for (const country of countries) {
                              const countryCities = await this.fetchCities(country.country_id);
                              for (const city of countryCities) {
                                const cityLocations = await this.fetchLocations(city.city_id);
                                locationsToSave.push(...cityLocations);
                              }
                            }
                        }
                    }
                    else{
                        const countryCities = await this.fetchCities(this.selectedCountry.country_id);
                          for (const city of countryCities) {
                            const cityLocations = await this.fetchLocations(city.city_id);
                            locationsToSave.push(...cityLocations);
                          }
                    }
                }
                else{                    
                    const cityLocations = await this.fetchLocations(this.selectedCity.city_id);
                    locationsToSave.push(...cityLocations);
                }
              } else {            
                  locationsToSave.push({ location_id: this.selectedLocation.location_id });                
              }
              const uniqueLocationIds = Array.from(new Set(locationsToSave
                  .map(location => location.location_id)
                  .filter(id => id !== 0)
                ));
              this.locations_users = uniqueLocationIds.join(',');
              if (!this.allSelectedCustomers.includes(this.selectedCustomer.customer_id)) {
                  this.allSelectedCustomers.push(this.selectedCustomer.customer_id);
                }
              if (typeof this.locations_users === 'string') {
                  // Divide la cadena separada por comas en un array de elementos
                  const locationsArray = this.locations_users.split(',');

                  // Itera sobre cada elemento del array resultante
                  locationsArray.forEach(location => {
                    location = location.trim(); // Elimina espacios en blanco alrededor

                    // Verifica si el elemento ya existe en allSelectedLocations antes de añadirlo
                    if (!this.allSelectedLocations.includes(location)) {
                      this.allSelectedLocations.push(location);
                    }
                  });
                } else {
                  // Si no es una cadena, simplemente verifica y añade el elemento como antes
                  if (!this.allSelectedLocations.includes(this.locations_users)) {
                    this.allSelectedLocations.push(this.locations_users);
                  }
                }
                if (!this.allSelectedCountries.includes(this.selectedCountry.country_id)) {
                  this.allSelectedCountries.push(this.selectedCountry.country_id);
                }
                if (!this.allSelectedCities.includes(this.selectedCity.city_id)) {
                    this.allSelectedCities.push(this.selectedCity.city_id);
                }
            },
            async fetchAllCustomers() {
              try {
                const url = `${process.env.VUE_APP_BACK}/customers`;
                const response = await axios.get(url);
                this.customers = response.data;
                this.customers.unshift({ customer_id: 0, customer_name: "Todos" });
                return response.data;
              } catch (error) {
                console.error(error);
              }
            },
            async fetchAllCountries() {
              try {
                const url = `${process.env.VUE_APP_BACK}/countries/all`;
                const response = await axios.get(url);
                this.countries = response.data;
                this.countries.unshift({ country_id: 0, country_name: "Todos" });
                return response.data;
              } catch (error) {
                console.error(error);
              }
            },
            async fetchCountries(customerId) {
              try {
                const url = `${process.env.VUE_APP_BACK}/countries/all/multiplecustomer?customersID=${customerId}`;
                const response = await axios.get(url);
                this.countries = response.data;
                this.countries.unshift({ country_id: 0, country_name: "Todos" });
                return response.data;
              } catch (error) {
                console.error(error);
              }
            },
            async fetchAllCities() {
              try {
                const url = `${process.env.VUE_APP_BACK}/cities`;
                const response = await axios.get(url);
                this.cities = response.data;
                this.cities.unshift({ city_id: 0, city_name: "Todos" });
                return response.data;
              } catch (error) {
                console.error(error);
              }
            },
            async fetchCities(countryId) {
              try {
                const url = `${process.env.VUE_APP_BACK}/cities/all?countriesId=${countryId}`;
                const response = await axios.get(url);
                if (Array.isArray(response.data)) {
                  this.cities = response.data;
                } else {
                  this.cities = [];
                }
                this.cities.unshift({ city_id: 0, city_name: "Todos" });
                return response.data;
              } catch (error) {
                console.error(error);
              }
            },            
            async fetchAllLocationsRender() {
              try {
                const url = `${process.env.VUE_APP_BACK}/locations`;
                const response = await axios.get(url);
                this.locationsAll = response.data;
                this.locationsAll.unshift({ location_id: 0, location_name: "Todos" });
              } catch (error) {
                console.error(error);
              }
            },            
            async fetchAllCountriesRender() {
              try {
                const url = `${process.env.VUE_APP_BACK}/countries`;
                const response = await axios.get(url);
                this.countriesAll = response.data;
                this.countriesAll.unshift({ country_id: 0, country_name: "Todos" });
              } catch (error) {
                console.error(error);
              }
            },            
            async fetchAllCitiesRender() {
              try {
                const url = `${process.env.VUE_APP_BACK}/cities`;
                const response = await axios.get(url);
                this.citiesAll = response.data;
                this.citiesAll.unshift({ city_id: 0, city_name: "Todos" });
              } catch (error) {
                console.error(error);
              }
            },
            async fetchAllLocations() {
              try {
                const url = `${process.env.VUE_APP_BACK}/locations`;
                const response = await axios.get(url);
                this.locations = response.data;
                this.locations.unshift({ location_id: 0, location_name: "Todos" });
                return response.data;
              } catch (error) {
                console.error(error);
              }
            },
            async fetchLocations(cityId) {
              try {
                const url = `${process.env.VUE_APP_BACK}/locations/all/cities/${cityId}`;
                const response = await axios.get(url);
                this.locations = response.data;
                this.locations.unshift({ location_id: 0, location_name: "Todos" });
                return response.data;
              } catch (error) {
                console.error(error);
              }
            },           
            getUsers(data){
                let me = this;                
                let url = process.env.VUE_APP_BACK+'/users/all';//+me.page+'/'+me.porpag+'/'+me.searchtext;
                if(this.searchtext=='' || this.searchtext == null){
                    this.searchtext=null;
                    axios.get(url).then(function (response) {
                        me.arrayUsers = response.data;                
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                }
                else{
                    axios.get(url, { params: {
                            searchtext: this.searchtext
                    }})
                    .then((response) => {                                                       
                        me.arrayUsers = response.data;  
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                }                            
            },
            getRoles(){
                let me = this;  
                let url = process.env.VUE_APP_BACK+'/users/roles/all';
                axios.get(url).then(function (response) {
                        me.arrayRoles = response.data;          
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            },
            getInspectorsTypes(){
                let me = this;  
                let url = process.env.VUE_APP_BACK+'/users/inspectors/all';
                axios.get(url).then(function (response) {
                        me.arrayInspectorsTypes = response.data;
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            },
            getCustomers(){
                let me = this;  
                let url = process.env.VUE_APP_BACK+'/customers';
                axios.get(url).then(function (response) {
                        me.customers = response.data;
                        me.customers.unshift({ customer_id: 0, customer_name: "Todos" });
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            },
            getCountries(customer){
                let me = this; 
                let idCustomers = customer.customer_id;
                //});
                let url = process.env.VUE_APP_BACK+'/countries/all/multiplecustomer?customersID=' + idCustomers;
                axios.get(url).then(function (response) {
                        me.countries = response.data;
                        me.countries.unshift({ country_id: 0, country_name: "Todos" });         
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                this.countries = me.countries; 
            },
            getCities(country){
                let me = this; 
                let idCountries= country.country_id;
                let url = process.env.VUE_APP_BACK+'/cities/all?countriesId=' + idCountries;
                axios.get(url).then(function (response) {
                        if (Array.isArray(response.data)) {
                          me.cities = response.data;
                        } else {
                          me.cities = [];
                        }
                        me.cities.unshift({ city_id: 0, city_name: "Todos" });
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    });
            },
            getLocations(city){
                let me = this; 
                let idCities = city.city_id;
                let url = process.env.VUE_APP_BACK+'/locations/all/cities/' + idCities;
                axios.get(url).then(function (response) {
                        me.locations = response.data;
                        me.locations.unshift({ location_id: 0, location_name: "Todos" });
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    });
            },
            cleanValuesForm(data) {
                if (data == "all"){                    
                    this.selectedCountry = [];
                    this.countries = [];
                    this.selectedCity = [];
                    this.cities = [];
                }

                if (data == "second"){
                    this.selectedCity = [];
                    this.cities = [];
                }

                this.selectedLocation = [];
                this.locations = [];
            },
            customerSelected(){
                this.cleanValuesForm("all");
                this.getCountries(this.selectedCustomer);
            },
            countrySelected(){
                this.cleanValuesForm("second");
                this.getCities(this.selectedCountry);
            },
            citySelected(){
                this.cleanValuesForm("third");
                this.getLocations(this.selectedCity);
            },
            locationsSelected(){
                this.locationSend = this.selectedLocation.location_id;
            },
            toggleModal() {
                this.getInspectorsTypes();
                this.getRoles();
                this.getCustomers();
                this.$refs['createUser'].toggle('#toggle-btn')
              },
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                this.emailState = valid
                this.nameState = valid
                this.passwordState = valid
                this.roleState = valid
                if (valid)
                    return valid
                else {
                    if (this.repeatpassword != this.password){
                        this.error = "Password doesn't match"
                    }
                    else{                        
                        this.error = "Error. Check every field please"
                    }
                }
            },
            resetModalcreateUser() {
                this.email = ''
                this.name = ''
                this.password = ''
                this.role = 0
                this.repeatpassword = ''
                this.error = null
                this.emailState = null
                this.passwordState = null
                this.roleState = null
            },
            formatDate(date) {
              return moment(date).format('DD/MM/YYYY');
            },
            getCustomerName(customerId) {
              const customer = this.customers.find(customer => customer.customer_id === customerId);
              return customer ? customer.customer_name : 'Unknown';
            },
            getCountryName(countryId) {
              const country = this.countriesAll.find(country => country.country_id === countryId);
              return country ? country.country_name : 'Unknown';
            },
            getCityName(cityId) {
              const city = this.citiesAll.find(city => city.city_id === cityId);
              return city ? city.city_name : 'Unknown';
            },
            getLocationName(locationId) {
              const id = parseInt(locationId); // Convertir locationId a número
              const location = this.locationsAll.find(location => location.location_id === id);
              return location ? location.location_name : 'Unknown';
            },
            handleOkcreateUser(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmitcreateUser()
            },
            handleSubmitcreateUser() {
                if (!this.checkFormValidity()) {
                  return
                }
                if (this.selectedRole) {
                    const roleID = this.selectedRole.role_id;
                    const inspectionTypeID = this.selectedInspectorType.inspection_type_id;
                    Swal.fire({
                      title: 'Creating new user',
                      icon: 'info',
                      html: "Email with links sended.",
                    });
                    axios
                        .post(process.env.VUE_APP_BACK+"/users/create", {
                            email: this.email,
                            user_name: this.name,
                            role_id: roleID,
                            password_hash: this.password,
                            inspection_type_id: inspectionTypeID,
                            customers: this.allSelectedCustomers,
                            countries: this.allSelectedCountries,
                            cities: this.allSelectedCities,
                            locations: this.allSelectedLocations
                        })
                        .then((response) => {
                            const createdUserID = response.data.user_id;
                            /*console.log("Usuario creado con ID:", createdUserID);
                            console.log("response.data: ", response.data);
                            console.log("this.locationSend: ", this.locationSend);
                            console.log("this.locations_users: ", this.locations_users);*/
                            this.resetModalcreateUser()
                            this.$refs['createUser'].toggle('#toggle-btn')
                            this.getUsers()
                        })
                        .catch(function (error) {
                            console.error(error);
                        });
                }

                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')                  
                })
                
            },
            moment
        },
        mounted() {            
           this.getUsers();
           this.fetchAllLocationsRender();
           this.fetchAllCountriesRender();
           this.fetchAllCitiesRender();
        },
  created() {
    this.fetchAllCustomers();
  }
    }
</script>
