<template>

  <div>
    <div class="row row-fluid justify-content-between align-items-center action-bar">
      <div class="col-md-2">
        <b-icon-search class="inner"></b-icon-search><input class="form control-form busqueda" placeholder="Search location" v-model="searchtext" @change="getLocations">
      </div>
      <div class="col-md-2 d-flex align-items-end justify-content-end">
        <b-button class="btn btn-primary btndash" id="toggle-btn" @click="customerModal">➕ customer</b-button>
      </div>
      <div class="col-md-2 d-flex align-items-end justify-content-end">
        <b-button class="btn btn-primary btndash" id="toggle-btn" @click="countryModal">➕ country</b-button>
      </div>
      <div class="col-md-2 d-flex align-items-end justify-content-end">
        <b-button class="btn btn-primary btndash" id="toggle-btn" @click="cityModal">➕ city</b-button>
      </div>
      <div class="col-md-2 d-flex align-items-end justify-content-end">
        <b-button class="btn btn-primary btndash" id="toggle-btn" @click="locationModal">➕ location</b-button>
      </div>
    </div>
    
    <div class="container contentwh">      
        
        <div class="py-3 pl-6 pr-6">
            <div class="table-responsive">
                <table class="table table-borderless table-vertical-center listados">
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Country</th>
                            <th>City</th>
                            <th>Location</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="mb-3" v-for="location in locations" :key="location.location_id">
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="location.customer_name"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="location.country_name"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="location.city_name"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <span
                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg menu-link"
                                    v-text="location.location_name"
                                ></span>
                            </td>
                            <td class="pl-0">
                                <b-button class="btn btn-warning" id="edit" @click="editLocationModal(location)">✏️</b-button>
                            </td>
                            <td class="pl-0">
                                <b-button class="btn btn-danger" id="delete" @click="deleteLocation(location.location_id)">🗑️</b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

    <!-- -->
    <b-modal ref="createCustomer" title="Create Customer"
      @show="resetModalCreateCustomer"
      @hidden="resetModalCreateCustomer"
      @ok="handleOkCreateCustomer">      
        <form ref="form" @submit.stop.prevent="handleSubmitCreateCustomer">
            <div class="alert alert-danger" role="alert" v-if="error !== null">
                {{ error }}
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Customer </label>
                    <input id="customer" type="text" class="form-control" v-model="customer" required autofocus autocomplete="off" placeholder="Customer">
                </div>
            </div>      
        </form>
    </b-modal>

    <b-modal ref="createCountry" title="Create Country" 
      @show="resetModalCreateCountry"
      @hidden="resetModalCreateCountry"
      @ok="handleOkCreateCountry">      
        <form ref="form" @submit.stop.prevent="handleSubmitCreateCountry">
            <div class="alert alert-danger" role="alert" v-if="error !== null">
                {{ error }}
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Customer</label>
                    <select id="country" class="form-control" v-model="selectedCustomerRef" required placeholder="Customer">
                        <option v-for="customer in customers" v-bind:key="customer.customer_id" v-bind:value="customer">
                            {{ customer.customer_name }}
                        </option>
                    </select>
                </div>
            </div> 
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Country </label>
                    <input id="country" type="text" class="form-control" v-model="country" required autofocus autocomplete="off" placeholder="Country">
                </div>
            </div>      
        </form>
    </b-modal>


    <b-modal ref="createCity" title="Create City" 
      @show="resetModalCreateCity"
      @hidden="resetModalCreateCity"
      @ok="handleOkCreateCity">      
        <form ref="form" @submit.stop.prevent="handleSubmitCreateCity">
            <div class="alert alert-danger" role="alert" v-if="error !== null">
                {{ error }}
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Customer</label>
                    <select id="customer" class="form-control" v-model="selectedCustomerRef" @change="customerSelected" required placeholder="Customer">
                        <option v-for="customer in customers" v-bind:key="customer.customer_id" v-bind:value="customer">
                            {{ customer.customer_name }}
                        </option>
                    </select>
                </div>
            </div> 
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Country</label>
                    <select id="country" class="form-control" v-model="selectedCountryRef" required placeholder="Country">
                        <option v-for="country in countries" v-bind:key="country.country_id" v-bind:value="country">
                            {{ country.country_name }}
                        </option>
                    </select>
                </div>
            </div> 
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">City</label>
                    <input id="city" type="text" class="form-control" v-model="city" required autofocus autocomplete="off" placeholder="City">
                </div>
            </div>           
        </form>
    </b-modal>
    
    <b-modal ref="createLocation" title="Create Location" 
      @show="resetModalCreateLocation"
      @hidden="resetModalCreateLocation"
      @ok="handleOkCreateLocation">      
        <form ref="form" @submit.stop.prevent="handleSubmitCreateLocation">
            <div class="alert alert-danger" role="alert" v-if="error !== null">
                {{ error }}
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Customer</label>
                    <select id="customer" class="form-control" v-model="selectedCustomerRef" @change="customerSelected" required placeholder="Customer">
                        <option v-for="customer in customers" v-bind:key="customer.customer_id" v-bind:value="customer">
                            {{ customer.customer_name }}
                        </option>
                    </select>
                </div>
            </div> 
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Country</label>
                    <select id="country" class="form-control" v-model="selectedCountry" required placeholder="Country">
                        <option v-for="country in countries" v-bind:key="country.country_id" v-bind:value="country" >
                            {{ country.country_name }}
                        </option>
                    </select>
                </div>
            </div> 
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">City</label>
                    <select id="city" class="form-control" v-model="selectedCity" required placeholder="City">
                        <option v-for="city in cities" v-bind:key="city.city_id" v-bind:value="city">
                            {{ city.city_name }}
                        </option>
                    </select>
                </div>
            </div> 
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Location</label>
                    <input id="location" type="text" class="form-control" v-model="location" required autofocus autocomplete="off" placeholder="Location">
                </div>
            </div>              
        </form>
    </b-modal>

    <b-modal ref="editLocation" title="Edit Location" 
      @show="resetModalEditLocation"
      @hidden="resetModalEditLocation"
      @ok="handleOkEditLocation">      
        <form ref="form" @submit.stop.prevent="handleSubmitEditLocation">
            <div class="alert alert-danger" role="alert" v-if="error !== null">
                {{ error }}
            </div>
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Customer</label>
                    <select id="customer" class="form-control" v-model="selectedCustomerRef" @change="customerSelected" required placeholder="Customer">
                        <option v-for="customer in customers" v-bind:key="customer.customer_id" v-bind:value="customer">
                            {{ customer.customer_name }}
                        </option>
                    </select>
                </div>
            </div>  
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Country</label>
                    <select id="country" class="form-control" v-model="selectedCountry" required placeholder="Country">
                        <option v-for="country in countries" v-bind:key="country.country_id" v-bind:value="country" >
                            {{ country.country_name }}
                        </option>
                    </select>
                </div>
            </div> 
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">City</label>
                    <select id="city" class="form-control" v-model="selectedCityEdit" required placeholder="City">
                        <option v-for="city in cities" v-bind:key="city.city_id" v-bind:value="city">
                            {{ city.city_name }}
                        </option>
                    </select>
                </div>
            </div> 
            <div class="row-fluid row align-items-center">
                <div class="col-md-12">
                    <label class="form-group">Location</label>
                    <input id="location" type="text" class="form-control" v-model="location" required autofocus autocomplete="off" placeholder="Location">

                </div>
            </div>              
        </form>
    </b-modal>

  </div>
</template>

<script>    
    import axios from 'axios';
    import Swal from 'sweetalert2'

    export default { 
        data(){
            return{                
                customers:[], 
                locations:[], 
                countries:[],
                cities:[],   
                customer:"",
                location:"",
                country:"",        
                city:"",        
                page: 1,
                total:0,
                porpag:5,
                error: null, 
                searchtext:null,
                selectedCountryRef: null,
                selectedCountry: "",
                selectedCountryEdit: "",
                selectedCityRef: null,
                selectedCity: "",
                selectedCityEdit: "",
                location_id: "",
                selectedCustomerRef: ""
            }
        },
        watch: {
            selectedCountry(newVal) {
            // Maneja el cambio de selección aquí si es necesario
            console.log('Nuevo país seleccionado:', newVal);
                //let url = process.env.VUE_APP_BACK+'/cities/country/'+ newVal.CountryID; 
                axios.get(process.env.VUE_APP_BACK+'/cities/all/country/'+newVal.country_id)
                        .then((response)=>{
                            console.log(response);
                            console.log(response);
                            if(response.status == 204){
                                this.cities = "Sin ciudades creadas";  
                            }   
                            this.cities = response.data;  
                            console.log("selectedCountry");
                            console.log(this.cities);
                        })
                        .catch((error)=>{
                            console.log(error);
                        });
            },
            
        },
        methods:{
            getLocations(data){
                let me =this;                
                let url = process.env.VUE_APP_BACK+'/locations/locations';//+me.page+'/'+me.porpag+'/'+me.searchtext;
                if(this.searchtext=='' || this.searchtext == null){
                    this.searchtext=null;
                    axios.get(url).then(function (response) {
                        //me.total = response.data.total
        
                        console.log(response.data);
                        me.locations = response.data;                
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    });
                }
                else{
                    axios.get(url, { params: {
                            searchtext: this.searchtext
                    }})
                    .then((response) => {                                                       
                        me.locations = response.data;  
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                }                            
            },
            getCustomers(){    
                let me = this;  
                let url = process.env.VUE_APP_BACK+'/customers/';
                axios.get(url).then(function (response) {
                        me.customers = response.data;             
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    });
                this.customers = me.customers; 
            },
            
            getCountries(customer_id){    
                let me = this;  
                console.log("customer en getCountries");
                console.log(customer_id);
                //customerID = 1;
                //customer_id = 1;
                let url = process.env.VUE_APP_BACK+'/countries/all/customer/' + customer_id;
                axios.get(url).then(function (response) {
                        me.countries = response.data;             
                    })
                    .catch(function (error) {
                        // handle error
                        console.error(error);
                    });
                this.countries = me.countries; 
            },
            customerSelected(){
                console.log("customerSelected: " + this.selectedCustomerRef.customer_id);
                this.getCountries(this.selectedCustomerRef.customer_id);
            },
            getCitiesById(id){
                axios.get(process.env.VUE_APP_BACK+'/cities/country/'+id)
                        .then((response)=>{
                            //console.log("getCitiesById");
                            //console.log(response.data);
                            this.cities = response.data;
                            console.log("cities");
                            console.log(this.cities);
                        })
            },
            customerModal() {
              this.$refs['createCustomer'].toggle('#toggle-btn')
            },
            countryModal() {
              this.$refs['createCountry'].toggle('#toggle-btn')
            },
            cityModal() {
                this.getCustomers();
                let customerSelected = this.selectedCustomerRef;
                console.log(customerSelected);
              //this.getCountries();
                this.$refs['createCity'].toggle('#toggle-btn')
              
            },
            locationModal() {
              this.getCountries();
              this.$refs['createLocation'].toggle('#toggle-btn')
            },
            editLocationModal(location) {
                Swal.fire({
                      title: 'Loading',
                      icon: 'info',
                      html: "Wait...",
                      showConfirmButton: false,
                      timer: 1200
                    });
                console.log("location");
                console.log(location);
                this.$refs['editLocation'].toggle('#toggle-btn')
                console.log("location.customer_id");
                console.log(location.customer_id);
                this.getCountries(location.customer_id);;                                         
                
                this.location = location.location_name
                this.location_id = location.location_id;

                this.selectedCustomerRef = this.customers.find(c => c.customer_id === location.customer_id);
                // Suponiendo que esta línea está dentro de un método en tu componente Vue
                setTimeout(() => {
                    this.selectedCountry = this.countries.find(c => c.country_id === location.country_id);
                }, 500); // 2000 milisegundos = 2 segundos
               
                setTimeout(() => {
                    this.selectedCityEdit = this.cities.find(city => city.city_id === location.city_id);
                }, 1000); // 2000 milisegundos = 2 segundos
                //this.customer = location.customer_id;
            },
            deleteLocation(location_id){          
                Swal.fire({
                  title: 'Are you sure?',
                  icon: 'error',
                  html: "You can't undo this action",
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: 'Yes',
                  denyButtonText: 'No',
                }).then((result) => {
                    console.log("location_id");
                    console.log(location_id);
                  if (result.isConfirmed) {
                    let me = this;
                    let url = process.env.VUE_APP_BACK+'/locations/'+ location_id;
                    axios
                        .delete(url, {
                            user: me.userId
                        })
                        .then((response) => {
                            //console.log(response.data.message);
                            //this.$router.push('/locations');
                            this.getLocations();
                        })
                        .catch(function (error) {
                            console.error(error);
                        });
                    Swal.fire('Deleted!', '', 'success')
                  }/* else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                  }*/
                });      
                
            },
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                this.emailState = valid
                this.passwordState = valid
                this.roleState = valid
                if (valid)
                    return valid
                else {
                    if (this.repeatpassword != this.password){
                        this.error = "Password doesn't match"
                    }
                    else{                        
                        this.error = "Error. Check every field please"
                    }
                }
            },
            resetModalCreateCustomer() {
                this.country = ''
            },
            resetModalCreateCountry() {
                this.country = ''
            },
            resetModalCreateCity() {
                this.country = '';
                this.city = '';
            },
            resetModalCreateLocation() {
                this.country = '';
                this.city = '';
                this.location = ''; 
            },
            resetModalEditLocation() {
                this.country = '';
                this.city = '';
                this.location = ''; 
            },
            handleOkCreateCustomer(bvModalEvent) {
                // Prevent modal from closing
                bvModalEvent.preventDefault()
                // Trigger submit handler
                this.handleSubmitCreateCustomer()
            },
            handleOkCreateCountry(bvModalEvent) {
                // Prevent modal from closing
                bvModalEvent.preventDefault()
                // Trigger submit handler
                this.handleSubmitCreateCountry()
            },
            handleOkCreateCity(bvModalEvent) {
                // Prevent modal from closing
                bvModalEvent.preventDefault()
                // Trigger submit handler
                this.handleSubmitCreateCity()
            },
            handleOkCreateLocation(bvModalEvent) {
                // Prevent modal from closing
                bvModalEvent.preventDefault()
                // Trigger submit handler
                this.handleSubmitCreateLocation()
            },
            handleOkEditLocation(bvModalEvent) {
                // Prevent modal from closing
                bvModalEvent.preventDefault()
                // Trigger submit handler
                this.handleSubmitEditLocation()
            },
            handleSubmitCreateCustomer() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                  return
                }
                console.log("this.customer");
                console.log(this.customer);
                axios
                    .post(process.env.VUE_APP_BACK+"/customers/create", {
                        customer_name: this.customer
                    })
                    .then((response) => {
                        this.resetModalCreateCustomer()
                        this.$refs['createCustomer'].toggle('#toggle-btn');
                        window.location.reload();
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                this.$nextTick(() => {
                  this.$bvModal.hide('modal-prevent-closing')                  
                })
            },
            handleSubmitCreateCountry() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                  return
                }
                console.log(this.country);
                axios
                    .post(process.env.VUE_APP_BACK+"/countries/create", {
                        country_name: this.country,
                        customer_id: this.selectedCustomerRef.customer_id
                    })
                    .then((response) => {
                        this.resetModalCreateCountry()
                        this.$refs['createCountry'].toggle('#toggle-btn')
                        window.location.reload();
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
                this.$nextTick(() => {
                  this.$bvModal.hide('modal-prevent-closing')                  
                })
            },
            handleSubmitCreateCity() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                  return
                }
                console.log(this.city);

                if (this.selectedCountryRef) {
                    const country_id = this.selectedCountryRef.country_id;
                    console.log(country_id);
                
                    axios
                        .post(process.env.VUE_APP_BACK+"/cities/create", {
                            city_name: this.city,
                            country_id: country_id
                        })
                        .then((response) => {
                            this.resetModalCreateCountry()
                            this.$refs['createCity'].toggle('#toggle-btn');
                            window.location.reload();
                        })
                        .catch(function (error) {
                            console.error(error);
                        });
                }
                this.$nextTick(() => {
                  this.$bvModal.hide('modal-prevent-closing')                  
                })
            },
            handleSubmitCreateLocation() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                  return
                }
               
                if (this.selectedCity) {
                    const city_id = this.selectedCity.city_id;
                    console.log(city_id);
                    axios
                        .post(process.env.VUE_APP_BACK+"/locations/create", {
                            city_id: city_id,
                            location_name: this.location
                        })
                        .then((response) => {
                            this.$refs['createLocation'].toggle('#toggle-btn');
                            this.resetModalCreateLocation();
                            this.getLocations();
                            window.location.reload();
                        })
                        .catch(function (error) {
                            console.error(error);
                        });
                    
                }
                
                this.$nextTick(() => {
                  this.$bvModal.hide('modal-prevent-closing')                  
                })
            },
            handleSubmitEditLocation() {
                console.log("test");
                console.log(this.selectedCountry.country_id);
                console.log(this.selectedCityEdit.city_id);
                console.log(this.location_id);
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                  return
                }
                axios
                       .put(process.env.VUE_APP_BACK+"/locations/"+this.location_id, {
                            country_id: this.selectedCountry.country_id,
                            city_id: this.selectedCityEdit.city_id,
                            location_name: this.location
                       })
                       .then((response) => {
                           this.$refs['editLocation'].toggle('#toggle-btn');
                           this.resetModalCreateLocation();
                           this.getLocations();
                       })
                       .catch(function (error) {
                           console.error(error);
                       });
                    
                
                
                this.$nextTick(() => {
                  this.$bvModal.hide('modal-prevent-closing')                  
                })
            },
        },
        mounted() {           
            this.getLocations();
            this.getCountries();
            this.getCustomers(); 
        }
    }
</script>
