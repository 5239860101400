<template>
  <div class="fullheight">
    <div class="headerorange">
      <div class="logo-sgs"><img alt="Logo" src="/logo-sgs.png" width="200" /></div>
    </div>
    <div class="pantallalogin login">    
      <div class="formlogin">
        <form action class="form" @submit.prevent="reset">
          <h1 class="title">Reset Password</h1>
          <p style="text-align:center">Enter your new password</p>
          <label class="form-label" for="#password">Password</label>
          <input
            v-model="password"
            class="form-input"
            type="password"
            id="password"
            required
            placeholder="Password"
          >
          <label class="form-label" for="#repeatpassword">Repeat Password</label>
          <input
            v-model="repeatpassword"
            class="form-input"
            type="password"
            id="repeatpassword"
            required
            placeholder="Repeat Password"
          >
          <p v-if="error" class="error">Has been an error.</p>
          <p v-if="infodata" v-text="infodata" class="error"></p>
          <div class="form-label row-fluid row align-items-center justify-content-between">
              <div class="col-md-6">
                <input class="form-submit" type="submit" value="Submit">
              </div>
              <div class="col-md-6">
                <router-link to="/login" class="form-submit cancelbtn">Cancel</router-link>
              </div>
            </div>
        </form>
      </div>
    </div>
    <div class="footer-login justify-content-center">
        <div class="row-fluid row align-items-center justify-content-center">
            <div class="col-md-12">
                <p style="text-align:center">&copy; 2024 SGS | BAGS</p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import auth from "../logic/auth";
import axios from "axios";
export default {
  data: () => ({
    password: "",
    repeatpassword: "",
    hash: null,
    infodata: null,
    error: false
  }),
  beforeCreate(){
      console.log("Deslogeado");      
      auth.deleteUserLogged();
    },
  methods: {    
    reset() {
      axios
        .post(process.env.VUE_APP_BACK+"/users/reset", {
            hash: this.hash,            
            password: this.password,
        })
        .then((response) => {
           console.log(response.data);
           this.infodata = response.data.msg;
        })
        .catch(function (error) {
            console.error(error);
        });
    }
  },
  mounted() {                 
   this.hash = this.$route.params.hash; 
  }
};
</script>

